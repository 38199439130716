<script setup>
import { ref, defineEmits } from 'vue'
import Popup from '@/components/Popup.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

const open = ref(true)

const emit = defineEmits(['confirmed'])

function updateOpen(e) {
  open.value = e
}

function confirm() {
  open.value = false
  emit('confirmed')
}
</script>

<template>
  <Popup
    class="sticky z-50"
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-[30px] lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
    :mobile="false"
    :open="open"
    :close-button="false"
    :overlay-exit="false"
    :back-style="{ backdropFilter: 'blur(100px)' }"
    :animate="false"
    @update:open="updateOpen"
  >
    <div>
      <div class="text-center text-2xl font-bold">Age Verification</div>
      <div class="text-center mt-4">
        This website contains age-restricted materials. By entering, you affirm
        that you are at least 18 years of age or the age of majority in your
        jurisdiction.
      </div>
      <div class="w-full mt-10 font-semibold gap-8">
        <ButtonComponent class="w-full py-3 px-6" @click="confirm">
          Enter
        </ButtonComponent>
      </div>
    </div>
  </Popup>
</template>
