<script setup>
import cdnFile from '@/helpers/cdnFile.js'
import { computed, inject, onMounted, reactive, ref, watch } from 'vue'
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { PlusIcon } from '@heroicons/vue/24/outline'
import Compass from '@/components/icons/Compass.vue'
import Chat from '@/components/icons/Chat.vue'
import Saved from '@/components/icons/Saved.vue'
import Generate from '@/components/icons/Generate.vue'
import Logout from '@/components/icons/Logout.vue'
import Settings from '@/components/icons/Settings.vue'
import Referral from '@/components/icons/Referral.vue'
import trash from '@/assets/icons/trash.svg'
import broom from '@/assets/icons/broom.svg'
import xClose from '@/assets/icons/x-close.svg'
import { useRoute, useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import { useStore } from 'vuex'
import {
  sidebarOpen,
  touchend,
  touchmove,
  touchstart,
} from '@/helpers/navigationMenu.js'
import { useToast } from 'vue-toast-notification'
import formatMessage from '@/helpers/formatMessage.js'
import Confirm from './Confirm.vue'
import NavigationListItem from './Navigation/NavigationListItem.vue'
import { useMixpanel } from '@/composables/mixpanel'
import AppHeader from './AppHeader.vue'
import logo from '@/assets/logo.svg'
import logoicon from '@/assets/logoicon.svg'
import { useWindowSize } from '@vueuse/core'
import Eggplant from '@/components/icons/Eggplant.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import useNavigationMenu from '@/composables/useNavigationMenu'

const mixpanel = useMixpanel()

const $router = useRouter()
const $route = useRoute()
const $store = useStore()
const $axios = inject('axios')
const $toast = useToast()

const chatSessionId = computed(() => $store.state.chatSessionId)

const typing = computed(() => $store.state.typing)
const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})

const discountPopupOpen = computed(() => $store.state.discountPopupOpen)
const chatView = computed(() => $route.path === '/chat')
const user = computed(() => $store.state.user)
const premadeModel = computed(() => $store.state.premadeModel)
const model = computed(() => $store.getters.model)
const modelActions = reactive({
  delete: null,
  clear: null,
})

const canDeleteModel = computed(() => $store.getters.models.length > 1)
const modelSelectedForChatClear = computed(() =>
  $store.getters.models.find((model) => model.id == modelActions.clear),
)
const modelSelectedForDeletion = computed(() =>
  $store.getters.models.find((model) => model.id == modelActions.delete),
)

function createModel() {
  $router.push('/create-model?from=chat')
}

const isHomePage = computed(() =>
  [
    '/',
    '/ai-boyfriend',
    '/ai-boyfriend/',
    '/ai-hentai',
    '/ai-hentai/',
  ].includes($route.path),
)

const generatorPopupOpen = computed(() => $store.state.generatorPopupOpen)

const currentRoutePath = ref('')
const pathChanged = ref(false)

watch($route, (value) => {
  if (currentRoutePath.value !== '/chat') {
    pathChanged.value = true
  }
})

function selectModel(model) {
  if (typing.value && $store.state.messages.length > 1) return

  mixpanel.track('chat_select_model', {
    chat_session_id: chatSessionId.value,
    model_name: model.name,
    model_id: model.id,
    model_metadata: model,
  })

  sidebarOpen.value = false
  pathChanged.value = false

  console.log('model: ', model)

  $store.commit('SET_SELECTED_MODEL', model.id)
}
function onClearModel(model) {
  mixpanel.track('chat_clear_model_click', {
    chat_session_id: chatSessionId.value,
    model_name: model.name,
    model_id: model.id,
    model_metadata: model,
  })

  modelActions.clear = model.id
}
function onDeleteModel(model) {
  mixpanel.track('chat_delete_model_click', {
    chat_session_id: chatSessionId.value,
    model_name: model.name,
    model_id: model.id,
    model_metadata: model,
  })

  modelActions.delete = model.id
}
async function confirmClearModel() {
  const oldModel = modelActions.clear
  const oldModelId = oldModel
  const loader = $loading.show()

  mixpanel.track('chat_clear_model_confirm', {
    chat_session_id: chatSessionId.value,
    model_name: oldModel.name,
    model_id: oldModel.id,
    model_metadata: oldModel,
  })

  try {
    const resp = await $axios
      .post('/user/clear-model', {
        modelId: oldModelId,
      })
      .then((response) => response.data)
    $store.commit('ADD_MODEL', resp.model)
    $store.commit('SET_SELECTED_MODEL', resp.model.id)
    $store.commit('DELETE_MODEL', oldModelId)

    modelActions.clear = null
  } catch (e) {
    console.log(e)
    const message = e?.response?.data?.message
    $toast.error(message || 'Unable to perform action. Please try again later.')
  }

  loader.hide()
}
async function confirmDeleteModel() {
  // not allowed to delete models
  if ($store.getters.models.length <= 1) {
    return
  }

  const toBeDeletedModel = modelActions.delete
  const toBeDeletedModelId = toBeDeletedModel

  mixpanel.track('chat_delete_model_confirm', {
    chat_session_id: chatSessionId.value,
    model_name: toBeDeletedModel.name,
    model_id: toBeDeletedModel.id,
    model_metadata: toBeDeletedModel,
  })

  const loader = $loading.show()
  try {
    await $axios.post('/user/delete-model', {
      modelId: toBeDeletedModelId,
    })
    if (toBeDeletedModelId === $store.state.selectedModel) {
      const nextModel = $store.getters.models.find(
        (model) => model.id !== toBeDeletedModelId,
      )
      $store.commit('SET_SELECTED_MODEL', nextModel.id)
    }
    $store.commit('DELETE_MODEL', toBeDeletedModelId)

    modelActions.delete = null
  } catch (e) {
    console.log(e)
    const message = e?.response?.data?.message
    $toast.error(message || 'Unable to perform action. Please try again later.')
  }

  loader.hide()
}

const windowSize = useWindowSize()

const windowWidth = computed(() => windowSize.width.value)

const {
  goHome,
  goToGenerator,
  goToChat,
  goToSaved,
  goToRater,
  goToAffiliate,
  goToSettings,
  logout,
} = useNavigationMenu()

const mobileMenuOpen = computed(() => $store.state.mobileMenuOpen)

function toggleMobileMenu(value) {
  $store.commit('SET_MOBILE_MENU_OPEN', {
    open: value,
  })
}
</script>

<template>
  <template v-if="$route.meta.hasSideBar">
    <!-- Static sidebar for mobile -->
    <TransitionRoot
      v-if="chatView && windowWidth < 1024 && !mobileMenuOpen"
      as="template"
      :show="sidebarOpen && chatView"
    >
      <Dialog
        as="div"
        class="relative z-40 lg:hidden"
        @touchstart="touchstart"
        @touchmove="touchmove"
        @touchend="$store.state.selectedModel ? touchend : () => {}"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-[#10091f]/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            :enter-from="pathChanged ? 'translate-x-0' : '-translate-x-full'"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative flex w-full flex-1">
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="w-full bg-[#070917] flex flex-col overflow-y-auto overflow-x-hidden pb-[16px] ring-1 ring-white/10"
              >
                <AppHeader />
                <div
                  class="px-4 flex-1"
                  :class="discountPopupOpen ? 'pt-[90px]' : ''"
                >
                  <div
                    class="flex flex-col h-full overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
                  >
                    <div
                      class="flex justify-center items-center bg-[#0A0D1E] py-[27px] cursor-pointer rounded-tl-[15px] rounded-tr-[15px] border border-white border-opacity-5"
                      @click="createModel()"
                    >
                      <div class="flex flex-col">
                        <div class="text-[19px] mr-[21px]">Create New</div>
                      </div>
                      <div
                        class="flex items-center justify-center h-[30px] w-[30px] rounded-full border border-white border-opacity-15"
                        style="
                          background: radial-gradient(
                            255.14% 174.74% at 38.76% 155.71%,
                            #cc47ff 0%,
                            #9a5cff 100%
                          );
                          box-shadow:
                            0px 0px 2.5px 0px #1cae6e,
                            0px 0px 0px 0.99px #b552ff;
                        "
                      >
                        <PlusIcon class="h-6 w-6"></PlusIcon>
                      </div>
                    </div>
                    <div
                      class="flex flex-col gap-[15px] flex-1 bg-[#0A0D1E] py-[17px] rounded-b-[15px] border border-t-0 border-white border-opacity-5"
                    >
                      <div
                        v-for="displayModel in user?.models"
                        :key="displayModel.id"
                        class="flex gap-[15px] items-center bg-[#0E122A] mx-3 px-[15px] py-[21px] cursor-pointer min-h-14 rounded-[15px]"
                        :class="{
                          'border border-[#B651FF]':
                            model?.id === displayModel.id,
                        }"
                        :style="
                          model?.id === displayModel.id
                            ? { 'box-shadow': '0px 0px 0px 3px #B552FF26' }
                            : {}
                        "
                        @click="selectModel(displayModel)"
                      >
                        <div
                          class="h-[42px] w-[42px] rounded-full overflow-hidden shrink-0"
                        >
                          <picture v-if="displayModel.premadeId">
                            <source
                              media="(min-width: 768px)"
                              :srcset="`/models/avif/${displayModel.name.toLowerCase()}.avif`"
                              type="image/avif"
                            />
                            <source
                              media="(min-width: 768px)"
                              :srcset="`/models/webp/${displayModel.name.toLowerCase()}.webp`"
                              type="image/webp"
                            />

                            <source
                              media="(max-width: 767px)"
                              :srcset="`/models/avif/${displayModel.name.toLowerCase()}-sm.avif`"
                              type="image/avif"
                            />
                            <source
                              media="(max-width: 767px)"
                              :srcset="`/models/webp/${displayModel.name.toLowerCase()}-sm.webp`"
                              type="image/webp"
                            />
                            <img
                              class="w-full rounded-xl aspect-[832/1216]"
                              :src="`@/models/webp/${displayModel.name.toLowerCase()}-sm.webp`"
                              :alt="displayModel.name"
                              style="
                                user-select: none;
                                user-drag: none;
                                pointer-events: none;
                              "
                              draggable="false"
                            />
                          </picture>
                          <img
                            v-else
                            class="w-full rounded-xl aspect-[832/1216]"
                            :src="cdnFile(displayModel.referenceImage)"
                            :alt="displayModel.name"
                            style="
                              user-select: none;
                              user-drag: none;
                              pointer-events: none;
                            "
                            draggable="false"
                          />
                        </div>
                        <div class="flex flex-col truncate w-full">
                          <div class="flex items-center gap-[9px]">
                            <p class="text-[15px]">
                              {{ displayModel.name }}
                            </p>
                            <div
                              class="w-[10px] h-[10px] bg-[#55C421] rounded-full"
                              style="box-shadow: 0px 0px 0px 3px #00ff7526"
                            ></div>
                          </div>
                          <div
                            class="text-[13px] max-w-[145px] text-[#B1B5DB] truncate"
                            v-html="
                              formatMessage(
                                displayModel?.messages?.[0]?.content,
                              )
                            "
                          ></div>
                        </div>
                        <div
                          class="flex items-end pb-1 justify-center h-full gap-[9px]"
                        >
                          <button
                            v-if="user?.subscription"
                            class="flex justify-center items-center bg-[#11152D] w-[35px] h-[35px] rounded-full"
                            style="
                              box-shadow:
                                0px 0px 0px 1.2px #1e1a3d,
                                0px 0px 12px 2.4px #13142e inset;
                            "
                            @click.stop="onClearModel(displayModel)"
                          >
                            <img class="w-[21px] h-[21px]" :src="broom" />
                          </button>
                          <button
                            v-if="canDeleteModel"
                            class="flex justify-center items-center bg-[#11152D] w-[35px] h-[35px] rounded-full"
                            style="
                              box-shadow:
                                0px 0px 0px 1.2px #1e1a3d,
                                0px 0px 12px 2.4px #13142e inset;
                            "
                            @click.stop="onDeleteModel(displayModel)"
                          >
                            <img class="w-[21px] h-[21px]" :src="trash" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="premadeModel"
                      class="flex gap-x-3 items-center px-6 py-3 cursor-pointer min-h-14 relative"
                      :class="
                        model?.id === premadeModel.id
                          ? 'bg-gray-600'
                          : 'hover:bg-gray-700'
                      "
                      @click="selectModel(premadeModel)"
                    >
                      <div
                        class="h-14 w-14 bg-gray-500 rounded-full overflow-hidden shrink-0"
                      >
                        <picture>
                          <source
                            media="(min-width: 768px)"
                            :srcset="`/models/avif/${premadeModel.name.toLowerCase()}.avif`"
                            type="image/avif"
                          />
                          <source
                            media="(min-width: 768px)"
                            :srcset="`/models/webp/${premadeModel.name.toLowerCase()}.webp`"
                            type="image/webp"
                          />

                          <source
                            media="(max-width: 767px)"
                            :srcset="`/models/avif/${premadeModel.name.toLowerCase()}-sm.avif`"
                            type="image/avif"
                          />
                          <source
                            media="(max-width: 767px)"
                            :srcset="`/models/webp/${premadeModel.name.toLowerCase()}-sm.webp`"
                            type="image/webp"
                          />
                          <img
                            class="w-full rounded-xl aspect-[720/1051]"
                            :src="`/models/webp/${premadeModel.name.toLowerCase()}-sm.webp`"
                            :alt="premadeModel.name"
                            style="
                              user-select: none;
                              user-drag: none;
                              pointer-events: none;
                            "
                            draggable="false"
                          />
                        </picture>
                      </div>
                      <div class="flex flex-col w-full truncate">
                        <div class="font-semibold">{{ premadeModel.name }}</div>
                        <div
                          class="text-sm truncate max-w-full"
                          v-html="
                            formatMessage(premadeModel?.messages?.[0]?.content)
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Navigation menu -->
    <MobileMenu v-if="!generatorPopupOpen" />

    <!-- Static sidebar for desktop -->
    <div
      class="hidden relative py-[18px] pl-[15px] z-50 lg:fixed lg:inset-y-0 lg:z-5 lg:flex rounded-xl"
      :class="chatView ? 'lg:w-[463px]' : 'lg:w-70'"
      :style="{
        top: discountPopupOpen ? '3.5rem' : '0px',
        height: discountPopupOpen ? 'calc(100vh - 3.5rem)' : '100vh',
      }"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="bg-[#070917] flex justify-center grow flex-row gap-y-5 rounded-[25px]"
        :class="chatView ? '' : 'expand'"
      >
        <nav
          class="flex flex-col justify-center pt-[50px] rounded-[25px] border-[1px] w-[250px] border-[#FFFFFF0D]"
          style="
            background: linear-gradient(0deg, #0a0d1e, #0a0d1e),
              radial-gradient(
                123.96% 123.96% at 18.89% 102.14%,
                rgba(76, 12, 81, 0.2) 0%,
                rgba(37, 6, 38, 0) 100%
              );
          "
          :class="chatView ? 'max-w-[101px] rounded-r-none pt-[20px]' : ''"
        >
          <ul
            role="list"
            class="flex flex-1 flex-col gap-y-[15px]"
            :class="chatView ? 'mx-auto' : 'mx-8'"
          >
            <li v-if="!chatView">
              <div
                class="flex h-10 shrink-0 justify-center items-center mb-[45px]"
              >
                <router-link to="/" @click.prevent.stop.capture="goHome(true)"
                  ><img
                    class="w-[153px] h-auto"
                    :src="logo"
                    alt="Logo"
                    loading="eager"
                    fetchpriority="high"
                /></router-link>
              </div>
            </li>
            <li class="mb-[45px]" v-else>
              <router-link
                to="/"
                @click.prevent.stop.capture="goHome(true)"
                class="flex justify-center items-center group h-10 gap-x-3 rounded-[15px] p-2 text-sm font-semibold leading-6 text-gray-100 hover:bg-gray-800 hover:text-white"
              >
                <img class="h-[22px] w-[22px]" :src="logoicon" alt="Logo" />
              </router-link>
            </li>
            <NavigationListItem
              :to="isHomePage ? $route.path : '/'"
              label="Explore"
              :icon="Compass"
              :active="isHomePage"
              :hideLabel="chatView"
              @click.prevent.stop.capture="goHome(false)"
            />
            <NavigationListItem
              to="/generator"
              label="Generator"
              :icon="Generate"
              :active="$route.path === '/generator'"
              :hideLabel="!!chatView"
              @click.prevent.stop.capture="goToGenerator"
            />
            <NavigationListItem
              to="/chat"
              label="Chat"
              :icon="Chat"
              :active="$route.path === '/chat'"
              :hideLabel="chatView"
              @click.prevent.stop.capture="goToChat"
            />
            <NavigationListItem
              to="/saved"
              label="Saved"
              :icon="Saved"
              :active="$route.path === '/saved'"
              :hideLabel="!!chatView"
              @click.prevent.stop.capture="goToSaved"
            />
            <NavigationListItem
              to="/rater"
              label="Rater"
              :icon="Eggplant"
              :active="$route.path === '/rater'"
              :hideLabel="!!chatView"
              :betaFeature="true"
              @click.prevent.stop.capture="goToRater"
            />
          </ul>
          <ul
            class="flex flex-col gap-[5px] m-[12px] mt-auto py-3 px-[11px] rounded-[20px]"
          >
            <NavigationListItem
              v-if="user"
              to="/"
              label="Logout"
              :icon="Logout"
              :active="false"
              :hideLabel="chatView"
              :externalLink="true"
              @click.prevent.stop.capture="logout"
            />
          </ul>
          <ul
            class="flex flex-col gap-[5px] m-[10px] mt-auto py-3 px-[11px] border-[1px] border-[#1B1F36] rounded-[20px]"
            :class="chatView ? 'mb-[15px] mx-[15.5px]' : ''"
          >
            <NavigationListItem
              to="/affiliate"
              label="Affiliate"
              :icon="Referral"
              :active="$route.path === '/affiliate'"
              :hideLabel="chatView"
              class="w-full"
              @click="goToAffiliate"
            />
            <NavigationListItem
              to="/settings"
              label="Settings"
              :icon="Settings"
              :active="$route.path === '/settings'"
              :hideLabel="chatView"
              class="w-full"
              @click.prevent.stop.capture="goToSettings"
            />
          </ul>
        </nav>
        <div
          v-if="chatView"
          class="w-[362px] bg-[#0A0D1E] overflow-y-scroll overflow-x-hidden border border-l-0 border-white border-opacity-5 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
        >
          <div class="flex flex-col">
            <div
              class="flex items-center justify-center gap-x-[21px] px-2 py-[25px] cursor-pointer sm:hover:bg-gray-900 mb-[17px] border-b border-white border-opacity-5"
              @click="createModel()"
            >
              <p class="text-[19px]">Create New</p>
              <div
                class="h-[30px] w-[30px] rounded-full flex items-center justify-center border border-white border-opacity-15"
                style="
                  background: radial-gradient(
                    255.14% 174.74% at 38.76% 155.71%,
                    #cc47ff 0%,
                    #9a5cff 100%
                  );
                  box-shadow:
                    0px 0px 2.5px 0px #1cae6e,
                    0px 0px 0px 0.99px #b552ff;
                "
              >
                <PlusIcon class="h-6 w-6"></PlusIcon>
              </div>
            </div>
            <div
              v-for="displayModel in user?.models"
              :key="displayModel.id"
              class="flex gap-x-[15px] items-center bg-[#0E122A] mx-[12px] mb-[15px] py-[22px] px-[15px] rounded-[15px] border-[1px] cursor-pointer min-h-14 relative hover:bg-gray-900"
              :class="
                model?.id == displayModel.id
                  ? 'border-[#B651FF] selected-chat'
                  : 'border-transparent'
              "
              @click="selectModel(displayModel)"
            >
              <div
                class="h-[43px] w-[43px] bg-gray-500 rounded-full overflow-hidden shrink-0"
              >
                <picture v-if="displayModel.premadeId">
                  <source
                    media="(min-width: 768px)"
                    :srcset="`/models/avif/${displayModel.name.toLowerCase()}.avif`"
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 768px)"
                    :srcset="`/models/webp/${displayModel.name.toLowerCase()}.webp`"
                    type="image/webp"
                  />

                  <source
                    media="(max-width: 767px)"
                    :srcset="`/models/avif/${displayModel.name.toLowerCase()}-sm.avif`"
                    type="image/avif"
                  />
                  <source
                    media="(max-width: 767px)"
                    :srcset="`/models/webp/${displayModel.name.toLowerCase()}-sm.webp`"
                    type="image/webp"
                  />

                  <img
                    class="w-full rounded-xl aspect-[832/1216]"
                    :src="`/models/webp/${displayModel.name.toLowerCase()}-sm.webp`"
                    :alt="displayModel.name"
                    style="
                      user-select: none;
                      user-drag: none;
                      pointer-events: none;
                    "
                    draggable="false"
                  />
                </picture>

                <!-- Else, fall back to CDN image -->
                <img
                  v-else
                  class="w-full rounded-xl aspect-[832/1216]"
                  :src="cdnFile(displayModel.referenceImage)"
                  :alt="displayModel.name"
                  style="
                    user-select: none;
                    user-drag: none;
                    pointer-events: none;
                  "
                  draggable="false"
                />
              </div>
              <div class="flex flex-col w-full truncate">
                <div class="flex items-center gap-[9px]">
                  <div class="">{{ displayModel.name }}</div>
                  <div
                    class="w-[10px] h-[10px] bg-[#55C421] rounded-full"
                    style="box-shadow: 0px 0px 0px 3px #00ff7526"
                  ></div>
                </div>
                <div
                  class="text-[13px] text-[#B1B5DB] truncate max-w-full"
                  v-html="formatMessage(displayModel?.messages?.[0]?.content)"
                ></div>
              </div>
              <div class="flex gap-[10px]">
                <div
                  v-if="user?.subscription"
                  class="rounded-full p-[7px] w-[35px] h-[35px]"
                  style="box-shadow: 0px 0px 0px 1.2000000476837158px #1e1a3d"
                  @click.stop="onClearModel(displayModel)"
                >
                  <img :src="broom" />
                </div>
                <div
                  v-if="canDeleteModel"
                  class="rounded-full p-[7px] w-[35px] h-[35px]"
                  :style="{
                    'box-shadow':
                      '0px 0px 0px 1.2000000476837158px #1E1A3D, 0px 0px 12px 2.4000000953674316px #13142E inset',
                  }"
                  @click.stop="onDeleteModel(displayModel)"
                >
                  <img :src="trash" />
                </div>
              </div>
            </div>
            <div
              v-if="premadeModel"
              class="flex gap-x-[15px] items-center bg-[#0E122A] mx-[12px] mb-[15px] py-[22px] px-[15px] rounded-[15px] border-[1px] cursor-pointer min-h-14 relative hover:bg-gray-900"
              :class="
                model?.id === premadeModel.id
                  ? 'border-[#B651FF] selected-chat'
                  : 'border-transparent'
              "
              @click="selectModel(premadeModel)"
            >
              <div
                class="h-[43px] w-[43px] bg-gray-500 rounded-full overflow-hidden shrink-0"
              >
                <picture>
                  <source
                    media="(min-width: 768px)"
                    :srcset="`/models/avif/${premadeModel.name.toLowerCase()}.avif`"
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 768px)"
                    :srcset="`/models/webp/${premadeModel.name.toLowerCase()}.webp`"
                    type="image/webp"
                  />

                  <source
                    media="(max-width: 767px)"
                    :srcset="`/models/avif/${premadeModel.name.toLowerCase()}-sm.avif`"
                    type="image/avif"
                  />
                  <source
                    media="(max-width: 767px)"
                    :srcset="`/models/webp/${premadeModel.name.toLowerCase()}-sm.webp`"
                    type="image/webp"
                  />
                  <img
                    class="w-full rounded-xl aspect-[720/1051]"
                    :src="`/models/webp/${premadeModel.name.toLowerCase()}-sm.webp`"
                    :alt="premadeModel.name"
                    style="
                      user-select: none;
                      user-drag: none;
                      pointer-events: none;
                    "
                    draggable="false"
                  />
                </picture>
              </div>
              <div class="flex flex-col w-full truncate">
                <div class="flex items-center gap-[9px]">
                  <div class="">{{ premadeModel.name }}</div>
                  <div
                    class="w-[10px] h-[10px] bg-[#55C421] rounded-full"
                    style="box-shadow: 0px 0px 0px 3px #00ff7526"
                  ></div>
                </div>
                <div
                  class="text-[13px] text-[#B1B5DB] truncate max-w-full"
                  v-html="formatMessage(premadeModel?.messages?.[0]?.content)"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirm
        popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-4 lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
        :open="modelActions.delete !== null"
        type="confirm"
        @update:open="
          (v) => {
            if (!v) modelActions.delete = null
          }
        "
        @confirm="confirmDeleteModel"
      >
        <div>
          <h2 class="text-center">
            Are you sure you want to delete
            {{ modelSelectedForDeletion?.name }}?
          </h2>
        </div>
      </Confirm>
      <Confirm
        popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-4 lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
        :open="modelActions.clear !== null"
        type="confirm"
        @update:open="
          (v) => {
            if (!v) modelActions.clear = null
          }
        "
        @confirm="confirmClearModel"
      >
        <div>
          <h2 class="text-center">
            Are you sure you want to clear your chat with
            {{ modelSelectedForChatClear?.name }}?
          </h2>
        </div>
      </Confirm>
    </div>
  </template>
</template>

<style scoped>
.selected-chat {
  box-shadow: 0px 0px 0px 3px #b552ff26;
}
</style>
