<script setup>
import facebook from '@/assets/icons/facebook.svg'
import instagram from '@/assets/icons/instagram.svg'
import mastercardSecure from '@/assets/icons/card-brands/ms.svg'
import mastercard from '@/assets/icons/card-brands/mastercard.svg'
import visaVerified from '@/assets/icons/card-brands/vbv.svg'
import visa from '@/assets/icons/card-brands/visa.svg'
import x from '@/assets/icons/x.svg'
import logo from '@/assets/logo.svg'

const corpName = import.meta.env.VITE_CORP_NAME
const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL
const supportPhone = import.meta.env.VITE_SUPPORT_PHONE
const twitterHandle = import.meta.env.VITE_TWITTER_HANDLE
const instagramHandle = import.meta.env.VITE_INSTAGRAM_HANDLE
const facebookHandle = import.meta.env.VITE_FACEBOOK_HANDLE
const corpAddress = import.meta.env.VITE_CORP_ADDRESS
const redirectDomainName = import.meta.env.VITE_REDIRECT_DOMAIN_NAME
</script>

<template>
  <div
    class="flex flex-col lg:gap-[30px] mt-[100px] lg:mt-[140px] lg:pb-[80px]"
  >
    <div
      class="flex flex-col gap-[25px] lg:gap-0 lg:flex-row justify-between items-center mb-[50px] lg:mb-0"
    >
      <img class="w-[190px] h-auto" :src="logo" alt="Logo" />
      <div class="flex gap-4 justify-center md:justify-start">
        <!-- <a
          target="_blank"
          rel="noopener"
          :href="`https://www.facebook.com/${facebookHandle}`"
        >
          <div class="bg-[#131331] p-[15px] px-[19px] rounded-full">
            <img :src="facebook" alt="Facebook logo" />
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener"
          :href="`https://instagram.com/${instagramHandle}`"
        >
          <div class="bg-[#131331] p-[15px] rounded-full">
            <img :src="instagram" alt="Instagram logo" />
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener"
          :href="`https://twitter.com/${twitterHandle}`"
        >
          <div class="bg-[#131331] p-[15px] rounded-full">
            <img :src="x" alt="X logo" />
          </div>
        </a> -->
      </div>
    </div>
    <div
      class="flex flex-col lg:flex-row items-center justify-between w-full md:text-left border border-white border-opacity-5 rounded-[15px] py-[35px] px-[30px]"
      style="
        background: radial-gradient(
          123.96% 123.96% at 18.89% 102.14%,
          rgba(76, 12, 81, 0.2) 0%,
          rgba(37, 6, 38, 0) 100%
        );
      "
    >
      <div
        class="text-[14px] text-center lg:text-left lg:flex-1 2xl:flex-[unset]"
      >
        <p>
          Copyright © 2024, <span>{{ corpName }}. All rights reserved.</span>
        </p>
        <p class="mb-[23px]">
          {{ corpAddress }}
        </p>
        <p>
          All content in this website is
          <span class="text-[#CC47FF]">AI-generated.</span>
        </p>
      </div>
      <div class="text-[14px]">
        <div
          class="flex flex-col items-center gap-[22px] mb-[38px] mt-[41px] lg:grid lg:grid-cols-[1fr_auto_1fr] lg:justify-items-end lg:gap-x-[22px] lg:gap-y-0 lg:mt-0 lg:mb-[23px]"
        >
          <router-link to="/terms-of-service">Terms of Service</router-link>
          <router-link to="/privacy-policy">Privacy Policy</router-link>
          <router-link to="/cancellation-policy"
            >Cancellation Policy</router-link
          >
          <div class="hidden lg:block"></div>
          <router-link to="/complaint-policy">Complaint Policy</router-link>
          <router-link to="/content-removal-policy"
            >Content Removal Policy</router-link
          >
        </div>
        <div class="flex justify-end gap-2">
          <span
            ><!--email_off-->{{ supportEmail
            }}<!--/email_off--></span
          >
          <span>{{ supportPhone }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row justify-between items-center">
      <div
        class="h-8 flex gap-4 my-[25px] lg:my-0 justify-center md:justify-start"
      >
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img class="w-[80%]" :src="visa" alt="Visa Logo" loading="lazy" />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            class="w-[70%]"
            :src="mastercard"
            alt="MasterCard Logo"
            loading="lazy"
          />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            :src="visaVerified"
            class="w-[80%]"
            alt="Verified By Visa Logo"
            loading="lazy"
          />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            class="w-[80%]"
            :src="mastercardSecure"
            alt="MasterCard SecureCode Logo"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex gap-[26px] mb-[30px] lg:mb-0">
        <router-link class="block lg:hidden text-[14px]" to="/affiliate"
          >Become an Affiliate</router-link
        >
        <!-- <a class="text-[14px]" href="/blog">Blog</a> -->
        <a
          class="text-[14px]"
          target="_blank"
          rel="nofollow noopener noreferrer"
          :href="`https://${redirectDomainName}/report-content`"
          >Report Content</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped></style>
